import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import SEO from '../../components/SEO';
import Event from '../../components/DevensCampusPage/Event';
import Wrapper from '../../components/Wrapper';
import EventsList from '../../components/DevensCampusPage/EventsList';
import Footer from '../../components/Footer';
import Header from '../../components/BasicPage/Header';
import NotFoundPage from '../../pages/404';

const DevensEventsPage = ({ location, data }) => {
  const pageData = data.prismic.allDevens_events_pages.edges.slice(0, 1).pop();

  if (!pageData || !pageData.node) {
    return <NotFoundPage />;
  }

  const {
    section,
    title,
    events: eventsRaw,
    featured_image,
    featured_imageSharp,
    featured_embed_video,
    seo,
    secondary_topper_cta_link,
    secondary_topper_cta_label,
  } = pageData.node;
  const titleRaw = RichText.asText(title);

  const events = eventsRaw
    .filter(event => event)
    .map(event => ({
      title: event.title,
      link: event.link,
      location: event.location,
      rsvpLink: event.rsvp_link,
      date: event.date,
      description: event.description,
    }));

  return (
    <>
      <SEO
        title={titleRaw}
        description={seo && seo.description ? seo.description : undefined}
        image={seo && seo.image ? seo.image : undefined}
        href={location.href}
      />

      <Header
        title={title}
        image={featured_image}
        sharpImage={featured_imageSharp}
        embedVideo={featured_embed_video}
        subnav={section ? section.navigation : null}
        overline={section.section_title}
        secondaryCtaLink={secondary_topper_cta_link}
        secondaryCtaLabel={secondary_topper_cta_label}
      />

      <Wrapper>
        <EventsList>
          {events.map(event => (
            <Event key={event.title} {...event} />
          ))}
        </EventsList>
      </Wrapper>

      <Footer />
    </>
  );
};

DevensEventsPage.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allDevens_events_pages: PropTypes.object,
    }),
  }),
};

export const featurePhotoFragment = graphql`
  fragment FeaturePhotoQuery on File {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 90) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const query = graphql`
  query DevensEventsQuery {
    prismic {
      allDevens_events_pages {
        edges {
          node {
            seo {
              ...PageSEOQuery
            }
            section {
              ... on PRISMIC_Site_section {
                section_title
                navigation {
                  label
                  link {
                    ...LinkQuery
                  }
                }
              }
            }

            title
            secondary_topper_cta_link {
              ...LinkQuery
            }
            secondary_topper_cta_label
            featured_image
            featured_imageSharp {
              ...FeaturePhotoQuery
            }
            featured_embed_video
            events {
              title: title1
              link {
                ...LinkQuery
              }
              date
              description
              location
              rsvp_link {
                ...LinkQuery
              }
            }
          }
        }
      }
    }
  }
`;

export default DevensEventsPage;
