import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RichText } from 'prismic-reactjs';

import Button from '../../Button';
import { linkResolver, contentSerializer } from '../../../utils';

import styles from './Event.module.scss';

const Event = ({ title, link, description, date, location, rsvpLink }) => (
  <li className={styles.container}>
    <div className={styles.content}>
      <div className={styles.top}>
        <p className={styles.date}>{moment(date).format('MMM DD, YYYY')}</p>
        {location ? <p className={styles.location}>{location}</p> : null}
      </div>
      <h2 className={styles.title}>
        {link ? (
          <a
            href={linkResolver(link)}
            target={link._linkType === 'Link.web' ? '_blank' : undefined}
            rel={link._linkType === 'Link.web' ? 'noopener noreferrer' : undefined}>
            {title}
          </a>
        ) : (
          title
        )}
      </h2>
      <p className={styles.description}>
        <RichText render={description} htmlSerializer={contentSerializer} />
      </p>
      {rsvpLink && moment(date).isSameOrAfter(moment(), 'day') ? (
        <div className={styles.button}>
          <Button text="RSVP" url={linkResolver(rsvpLink)} external />
        </div>
      ) : null}
    </div>
  </li>
);

Event.defaultProps = {
  location: null,
  rsvpLink: null,
};

Event.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.object,
  description: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  location: PropTypes.string,
  rsvpLink: PropTypes.object,
};

export default Event;
